<template>
	<div class="add-discount" style="margin-bottom: 200px;" v-loading="loading">
		<div class="flex" style="align-items: center;height: 60px;">
			<div class="flex" style="flex: 1;align-items: center;position: relative;">
				<div class="top-tab">1. 基本信息</div>
				<div :style="{background:activeTab==0?'#f5f5f5':'#006fa5'}" style="width: 20px;height: 60px;">
					<div v-if="activeTab==0" class="box"></div>
				</div>
			</div>
			<div class="flex" style="flex: 1;align-items: center;position: relative;">
				<div class="top-tab" :style="{background:activeTab>=1?'#006fa5':'#f5f5f5',color:activeTab>=1?'#fff':'#666'}">2. 奖项设置</div>
				<div :style="{background:activeTab<=1?'#f5f5f5':'#006fa5'}" style="width: 20px;height: 60px;">
					<div v-if="activeTab==1" class="box"></div>
				</div>
			</div>
			<div class="flex" style="flex: 1;align-items: center;position: relative;">
				<div class="top-tab" :style="{background:activeTab==2?'#006fa5':'#f5f5f5',color:activeTab==2?'#fff':'#666'}">3. 页面装修</div>
			</div>
		</div>
		<el-form v-if="activeTab==0" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<div style="padding: 15px;background: #fff;">
				<el-form-item label="活动名称" label-width="120px" prop="activeName">
					<el-input v-model="ruleForm.activeName" placeholder="请输入活动名称" maxlength="50" style="width:200px"></el-input>
				</el-form-item>

				<el-form-item label="活动时间" label-width="120px" prop="activityTime">
					<el-date-picker :disabled="onlyLook" v-model="ruleForm.activityTime" type="datetimerange" :picker-options="pickerOptions"
					range-separator="～" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</el-form-item>
				<el-form-item label="参与用户身份" label-width="120px">
					<el-radio v-model="ruleForm.radioRole" label="1">所有用户</el-radio>
					<el-radio v-model="ruleForm.radioRole" label="2">部分用户</el-radio>
				</el-form-item>
				<div style="margin-left: 120px;" v-if="ruleForm.radioRole==2">
					<div style="font-size: 12px;color: #666;margin-bottom: 10px;">通过推手级别筛选:</div>
					<el-form-item label-width="0" v-if="ruleForm.radioRole==2" prop="roleValue">
						<el-select style="width: 400px;" v-model="ruleForm.roleValue" size="mini" multiple placeholder="请选择">
                            <el-option label="普通用户" :value="0">
							</el-option>
                            <el-option label="新注册用户" :value="10">
							</el-option>
							<el-option v-for="item in LEVELLIST" :key="item.pusherLevel" :label="item.pusherLabel" :value="item.pusherLevel">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				<el-form-item style="margin-top: 20px;" label="抽奖条件" label-width="120px">
					<el-radio v-model="ruleForm.drawWay" label="0">不做限制</el-radio>
					<el-radio v-model="ruleForm.drawWay" label="1">下单抽奖</el-radio>
					<el-radio v-model="ruleForm.drawWay" label="2">邀请新用户注册</el-radio>
					<el-radio v-model="ruleForm.drawWay" label="3">下单 + 邀请新用户注册</el-radio>
				</el-form-item>
				<div style="margin-left: 120px;">
					<div v-if="ruleForm.drawWay==1 || ruleForm.drawWay==3" class="flex" style="align-items: center;margin-top: -30px;">
						<el-checkbox v-model="ruleForm.drawOptionOrder" label="订单金额>="></el-checkbox>
						<el-form-item v-if="ruleForm.drawOptionOrder" label-width="0" style="margin-top: 16px;" prop="orderMoney">
							<el-input onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);" v-model="ruleForm.orderMoney" size="mini" style="width: 120px;margin-left: 10px;"></el-input>
						</el-form-item>
						<el-form-item v-else label-width="0" style="margin-top: 16px;" prop="orderMoneyNormal" :required="false">
							<el-input onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);" v-model="ruleForm.orderMoneyNormal" size="mini" style="width: 120px;margin-left: 10px;"></el-input>
						</el-form-item>
						<div style="font-size: 14px;color: #333;margin-left: 4px;">元</div>
					</div>
					<div v-if="ruleForm.drawWay==1 || ruleForm.drawWay==3" class="flex" style="align-items: center;margin-top: -24px;">
						<el-checkbox @change="goodsCheck" v-model="ruleForm.drawOptionGoods" label="指定商品"></el-checkbox>
						<div style="height: 50px;margin-top: 16px;">
							<el-form-item v-if="ruleForm.drawOptionGoods" label-width="0" prop="drawGoods" style="padding-left: 20px;width: 200px;">
								<el-button type="primary" size="mini" @click="choseActive(1)">选择商品</el-button>
							</el-form-item>
						</div>
					</div>
                    <div v-if="ruleForm.drawWay==2 || ruleForm.drawWay==3" class="flex" style="align-items: center;margin-top: -30px;">
						<el-checkbox :disabled='!!activityId' v-model="ruleForm.drawOptioninv" v-if="ruleForm.drawWay==3" style="margin-right:10px;"></el-checkbox>
                        <div style="font-size: 14px;color: #333;">每邀请</div>
						<el-form-item label-width="0" style="margin-top: 18px;" prop="invitationCount" :rules="ruleForm.drawOptioninv || ruleForm.drawWay==2 ?[{required:true,message:'请输入邀请数量',trigger: 'blur'},{type:'number',min: 1,message:'邀请人次数不能小于1',trigger: 'blur'}]:[]">
							<el-input :disabled='!!activityId' :onkeyup="ruleForm.invitationCount = publicFormatNum(ruleForm.invitationCount,4)" v-model="ruleForm.invitationCount" size="mini" style="width: 120px;margin-left: 10px;"></el-input>
						</el-form-item>
						<div style="font-size: 14px;color: #333;margin-left: 4px;">人</div>
					</div>
                    <div style="font-size:14px;color:#999999;" v-if="ruleForm.drawWay==3">以上3个条件只要满足其中一个条件就能获得抽奖次数，如果都不勾选，每下单1次就能获得1次固定的抽奖次数</div>
				</div>
			</div>

			<div style="background: #fff;padding: 0 15px 30px 15px;" v-if="(ruleForm.drawWay==1 || ruleForm.drawWay==3) && ruleForm.drawOptionGoods">
				<div class="table-container" style="min-height: 30px;margin-bottom: 20px;margin-left: 120px;">
					<el-table :data="ruleForm.drawOptionGoods?goodsPageList:[]" style="width: 100%;" ref="productChose">
						<el-table-column prop="goodsName" label="商品名称"></el-table-column>
						<el-table-column label="商品图片" width="300">
							<template slot-scope="scope">
								<div class="product-info">
									<img v-if="scope.row.thumbImgUrl" :src="imgUrl + scope.row.thumbImgUrl" />
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="originalPrice" label="划线价"></el-table-column>
						<el-table-column prop="salePrice" label="销售价"></el-table-column>
						<el-table-column prop="totalStock" label="商品库存"></el-table-column>
						<el-table-column label="操作" width="200">
							<template slot-scope="scope">
								<span style="color:#F56C6C;cursor:pointer;" @click="deleteChose(scope.row)">删除</span>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination
						v-if="page.total"
						style="margin-top:20px;float:right;"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="page.current"
						:page-sizes="[5,10, 15, 20, 25, 30]"
						:page-size="page.size"
						layout="total, sizes, prev, pager, next, jumper"
						:total="page.total"
					></el-pagination>
				</div>
			</div>
            <div style="background: #fff;padding: 0 15px 30px 15px;margin-bottom: 10px;">
                <el-form-item label="参与次数" label-width="120px" >
					<div style="width: 350px;">
                        <div v-if="ruleForm.drawWay != 2 && ruleForm.drawWay != 3">
                            <el-radio v-model="ruleForm.drawNum" label="1" v-if="ruleForm.drawWay != 0">不做限制</el-radio>
                            <el-radio @change="drawNumChange" style="display: flex;align-items: center;" v-model="ruleForm.drawNum" label="2">
                                <div style="display: flex;align-items: center;width: 300px;">
                                    <div>每个账号每天可抽</div>
                                    <el-form-item v-if="ruleForm.drawNum==2" label-width="0" style="height: 36px;" prop="drawNumber1">
                                        <el-input onkeyup="this.value=this.value.replace(/\D/g,'')" size="mini" v-model.number="ruleForm.drawNumber1" style="width: 120px;margin-left: 4px;"></el-input>
                                    </el-form-item>
                                    <el-form-item v-else label-width="0" style="height: 36px;" prop="drawNumberNormal1" :required="false">
                                        <el-input onkeyup="this.value=this.value.replace(/\D/g,'')" size="mini" v-model.number="ruleForm.drawNumberNormal1" style="width: 120px;margin-left: 4px;"></el-input>
                                    </el-form-item>
                                    <div style="font-size: 14px;color: #333;margin-left: 4px;">次</div>
                                </div>
                            </el-radio>
                            <el-radio @change="drawNumChange" style="display: flex;align-items: center;margin-top:20px;" v-model="ruleForm.drawNum" label="3">
                                <div style="display: flex;align-items: center;width: 300px;">
                                    <div>每个账号一共可抽</div>
                                    <el-form-item v-if="ruleForm.drawNum==3" label-width="0" style="height: 36px;" prop="drawNumber2">
                                        <el-input onkeyup="this.value=this.value.replace(/\D/g,'')" size="mini" v-model.number="ruleForm.drawNumber2" style="width: 120px;margin-left: 4px;"></el-input>
                                    </el-form-item>
                                    <el-form-item v-else label-width="0" style="height: 36px;" prop="drawNumberNormal2" :required="false">
                                        <el-input onkeyup="this.value=this.value.replace(/\D/g,'')" size="mini" v-model.number="ruleForm.drawNumberNormal2" style="width: 120px;margin-left: 4px;"></el-input>
                                    </el-form-item>
                                    <div style="font-size: 14px;color: #333;margin-left: 4px;">次</div>
                                </div>
                            </el-radio>
                        </div>
						<div  v-else>
                            <div v-if="ruleForm.drawWay == 2 || ruleForm.drawOptioninv">
                                <div style="display: flex;align-items: center;width: 300px;">
                                    <div>邀请者  </div>
                                    <el-form-item label-width="0" style="height: 36px;margin-left:14px;" prop="inviterCount">
                                        <el-input :disabled='!!activityId' onkeyup="this.value=this.value.replace(/\D/g,'')" size="mini" v-model.number="ruleForm.inviterCount" style="width: 120px;margin-left: 4px;"></el-input>
                                    </el-form-item>
                                    <div style="font-size: 14px;color: #333;margin-left: 4px;">次</div>
                                </div>
                                <div style="display: flex;align-items: center;width: 600px;margin-top:20px;">
                                    <div>被邀请者</div>
                                    <el-form-item label-width="0" style="height: 36px;" prop="beInviterCount">
                                        <el-input onkeyup="this.value=this.value.replace(/\D/g,'')" size="mini" v-model.number="ruleForm.beInviterCount" style="width: 120px;margin-left: 4px;"></el-input>
                                    </el-form-item>
                                    <div style="font-size: 14px;color: #333;margin-left: 4px;">次</div>
                                    <div style="margin: 0 10px 0 20px;">是否需要支付</div>
                                    <el-form-item label-width="0" style="height: 36px;" prop="beInviterNeedPay">
                                        <el-switch v-model="ruleForm.beInviterNeedPay"></el-switch>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                        
					</div>
				</el-form-item>
            </div>
		</el-form>
		<el-form v-if="activeTab==1" :model="ruleForm2" :rules="rules" ref="ruleForm2" label-width="100px" class="demo-ruleForm">
			<div style="background: #fff;padding: 0 15px 30px 15px;margin-bottom: 10px;">
				<div class="flex" style="align-items: center;">
					<div>奖品设置</div>
					<el-form-item style="display: flex;align-items: center;padding-left: 20px;margin-top: 20px;" label-width="0">
						<el-button type="primary" size="mini" @click="addAwardDialogShow()">添加奖品</el-button>
					</el-form-item>
				</div>
				<el-table :data="awardGoodsList" style="width: 100%;" ref="productChose">
					<el-table-column prop="awardName" label="奖品名称" width="300"></el-table-column>
					<el-table-column prop="awardType" label="奖品类型">
						<template slot-scope="scope">
							<!-- 0-未中奖 1-商品 2-优惠券 3-积分 -->
							<div v-if="scope.row.awardType==0">未中奖</div>
							<div v-if="scope.row.awardType==1">实物</div>
							<div v-if="scope.row.awardType==2">{{scope.row.prizeTypeStr ? scope.row.prizeTypeStr : '优惠券'}}</div>
							<div v-if="scope.row.awardType==3">积分</div>
						</template>
					</el-table-column>
					<el-table-column label="图片">
						<template slot-scope="scope">
							<div>
								<img style="width: 60px;height: 60px;" :src="scope.row.awardLogo" alt="">
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="awardNum" label="数量"></el-table-column>
					<el-table-column prop="issuedGoodsCount" label="已发放"></el-table-column>
					<el-table-column label="中奖概率">
						<template slot-scope="scope">
							<div>{{scope.row.awardRate}}%</div>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="200">
						<template slot-scope="scope">
							<span style="color:#409EFF;cursor:pointer;" @click="eidtAwardInfo(scope.$index,scope.row)">编辑</span>
							<span style="color:#F56C6C;cursor:pointer;margin-left: 10px;" @click="delAwardInfo(scope.$index,scope.row)">删除</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-form>
        <!-- 页面装修 -->
		<el-form v-if="activeTab==2" :model="ruleForm3" :rules="rules" ref="ruleForm3" label-width="100px" class="demo-ruleForm">
			<div style="background: #fff;padding: 20px 15px 30px 15px;margin-bottom: 10px;">
				<div class="flex" style="align-items: center;">
					<div>页面装修</div>
				</div>
                <el-form-item label="样式选择" label-width="200px" prop="style" :rules="[{required:true,message:'样式选择不能为空',trigger: 'blur'}]">
                    <el-select v-model="ruleForm3.style" placeholder="请选择样式">
                        <el-option label="老虎机" :value="1"></el-option>
                        <el-option label="盲盒" :value="2"></el-option>
                    </el-select>
                </el-form-item>

                <uploadImg label='宣传图片' label-width="200px" prop='publicityImg' v-model="ruleForm3.publicityImg" 
                    required tips="在活动页顶部展示，支持JPG、PNG、GIF格式" width='300' 
                    :rules="[{required:true,message:`宣传图片不能为空`,trigger: 'blur'}]"/>

                <uploadImg label='抽奖图片' label-width="200px" prop='luckDrawImg' v-model="ruleForm3.luckDrawImg" 
                    required tips="在活动页顶部展示，支持JPG、PNG、GIF格式" width='300' 
                    :rules="[{required:true,message:`抽奖图片不能为空`,trigger: 'blur'}]"/>

                <uploadImg label='奖品图片' label-width="200px" prop='prizeImg' v-model="ruleForm3.prizeImg" 
                    required tips="在活动页顶部展示，支持JPG、PNG、GIF格式" width='300' 
                    :rules="[{required:true,message:`奖品图片不能为空`,trigger: 'blur'}]"/>

                <uploadImg label='中奖名单背景' label-width="200px" prop='winnerImg' v-model="ruleForm3.winnerImg" 
                    required tips="在活动页顶部展示，支持JPG、PNG、GIF格式" width='300' 
                    :rules="[{required:true,message:`中奖名单背景图片不能为空`,trigger: 'blur'}]"/>

                <uploadImg label='支付结果图片' label-width="200px" prop='payImg' v-model="ruleForm3.payImg" 
                    required tips="针对下单抽奖活动，该图片在支付结果页展示，支持JPG、PNG、GIF格式" width='300' 
                    :rules="[{required:true,message:`支付结果图片不能为空`,trigger: 'blur'}]"/>

                <uploadImg label='订单页图片' label-width="200px" prop='orderImg' v-model="ruleForm3.orderImg" 
                    required tips="针对下单抽奖活动，该图片在订单列表页展示，支持JPG、PNG、GIF格式" width='300' 
                    :rules="[{required:true,message:`订单页图片不能为空`,trigger: 'blur'}]"/>

                <el-form-item
						label="分享标题"
                        label-width="200px"
						:inline-message="true"
						prop="shareTitle"
						:rules="[{required:true,message:'分享标题不能为空',trigger: 'blur'},{ min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' }]"
					>
						<el-input v-model="ruleForm3.shareTitle" style="width: 300px;"></el-input>
                </el-form-item>

                <uploadImg label='分享图片' label-width="200px" prop='shareImgUrl' v-model="ruleForm3.shareImgUrl" 
                    required tips="建议5：4尺寸，支持JPG、PNG、GIF格式" width='250' height='200' 
                    :rules="[{required:true,message:`分享图片不能为空`,trigger: 'blur'}]"/>

                <el-form-item
                    label="更多优惠链接:"
                    label-width="200px" 
                    prop="linkUrl"
                >
                    <selectLink v-model="ruleForm3.linkUrl"/>
                </el-form-item> 

                <el-form-item label="未中奖提示语" label-width="200px" prop="notWinPrompt" :rules="[{required:true,message:`未中奖提示语不能为空`,trigger: 'blur'}]">
					<el-input v-model="ruleForm3.notWinPrompt" placeholder="请输入未中奖提示语" style="width:200px"></el-input>
                    <div>
                        <el-radio v-model="ruleForm3.notWinPromptType" :label="0">邀请好友</el-radio>
                        <el-radio v-model="ruleForm3.notWinPromptType" :label="1">再去逛逛</el-radio>
                        <selectLink v-model="ruleForm3.notWinLinkUrl" v-if="ruleForm3.notWinPromptType == 1" style="display: inline-block;"/>
                    </div>
				</el-form-item>

                <el-form-item label="无抽奖机会提示语" label-width="200px" prop="noChancePrompt" :rules="[{required:true,message:`未中奖提示语不能为空`,trigger: 'blur'}]">
					<el-input v-model="ruleForm3.noChancePrompt" placeholder="请输入未中奖提示语" style="width:200px"></el-input>
                    <div>
                        <el-radio v-model="ruleForm3.noChancePromptType" :label="0">邀请好友</el-radio>
                        <el-radio v-model="ruleForm3.noChancePromptType" :label="1">再去逛逛</el-radio>
                        <selectLink v-model="ruleForm3.noChanceLinkUrl" v-if="ruleForm3.noChancePromptType == 1" style="display: inline-block;"/>
                    </div>
				</el-form-item>



                <el-form-item label="中奖名单：" label-width="200px">
					<el-radio v-model="ruleForm3.showWinners" label="0">不展示</el-radio>
					<el-radio v-model="ruleForm3.showWinners" label="1">展示</el-radio>
				</el-form-item>

				<el-form-item label="活动规则说明：" label-width="200px" prop="rules">
					<div class="baseInformtion" style="margin-bottom: 150px;">
						<div class="baseBottom-box" style="margin-bottom:150px;">
							<quill-editor ref="myTextEditor" v-model="ruleForm3.rules" :options="quillOption" style="width:80%;min-height: 300px;height:650px;"></quill-editor>
						</div>
					</div>
				</el-form-item>
			</div>
		</el-form>
		<el-dialog title="选择商品" :visible.sync="addProductDialogShow" width="1400px" class="dialog">
			<select-produce :selectedData="ruleForm.drawGoods"
			@getSelectList="getSelectList" :visible.sync="addProductDialogShow" v-if="addProductDialogShow"></select-produce>
		</el-dialog>
		<el-dialog title="选择奖品" :visible.sync="addDrawProduceDialogShow" width="1400px" class="dialog">
			<select-draw-produce :selectedData="ruleForm2.awardGoods"
			@getSelectList="getDrawSelectList" :visible.sync="addDrawProduceDialogShow" v-if="addDrawProduceDialogShow"></select-draw-produce>
		</el-dialog>
		<div class="bottom-save-btn">
			<div v-if="activeTab==0" style="background: #fff;padding: 15px;display: flex;justify-content: left;padding-left: 50px;">
				<el-button style="width: 100px;" type="primary" @click="handleNext" :loading="loading">下一步</el-button>
			</div>
			<div v-if="activeTab==1" style="background: #fff;padding: 15px;display: flex;justify-content: left;padding-left: 50px;">
				<el-button style="width: 100px;" @click="handleBack">上一步</el-button>
				<el-button style="width: 100px;margin-left: 40px;" type="primary" @click="handleNext" :loading="loading">下一步</el-button>
			</div>
			<div v-if="activeTab==2" style="background: #fff;padding: 15px;display: flex;justify-content: left;padding-left: 50px;">
				<el-button style="width: 100px;" @click="handleBack">上一步</el-button>
				<el-button style="width: 100px;margin-left: 40px;" type="primary" @click="saveBtn" :loading="loading">保存</el-button>
			</div>
		</div>
		<el-dialog title="添加奖品" :visible.sync="addDrawDialogShow" width="1400px" class="dialog">
			<div style="height: 640px;overflow: auto;"> 
				<el-form :model="ruleForm2" :rules="rules" ref="ruleForm2" label-width="100px" class="demo-ruleForm">
					<div style="padding: 15px;background: #fff;">
						<el-form-item label="奖品名称" label-width="120px" prop="awardName">
							<el-input v-model="ruleForm2.awardName" placeholder="请输入奖品名称" maxlength="50" minlength="1" style="width:200px"></el-input>
						</el-form-item>
				
						<el-form-item label="奖品类型" label-width="120px" prop="awardType">
							<div style="width: 350px;display: flex;flex-direction: column;margin-top: 6px;">
								<el-radio style="display: flex;align-items: center;" v-model="ruleForm2.awardType" label="2">
									<div style="display: flex;align-items: center;height: 30px;">
										<div style="margin-right: 10px;">优惠券</div>
										<div v-if="ruleForm2.awardType==2">
                                            <el-select v-model="ruleForm2.awardCouponId" placeholder="请选择" @change='changeawardCoupon'>
                                                <el-option
                                                    v-for="item in couponList"
                                                    :key="item.id"
                                                    :label="item.couponName"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
										</div>
										<div v-if="ruleForm2.awardType==2&&ruleForm2.awardCoupon" style="margin-left: 10px;">可用数量({{ruleForm2.awardCoupon.num}})</div>
									</div>
								</el-radio>
								<el-radio style="display: flex;align-items: center;margin-top: 20px;" v-model="ruleForm2.awardType" label="0">未中奖</el-radio>
								<div class="flex" style="align-items: center;height: 40px;">
									<el-radio style="display: flex;align-items: center;margin-top: 20px;" v-model="ruleForm2.awardType" label="1">
										商品
									</el-radio>
									<div style="margin-top: 14px;">
										<el-form-item label-width="0" prop="choseAward" v-if="ruleForm2.awardType==1">
											<el-button type="primary" size="mini" @click="choseActive(2)">选择商品</el-button>
										</el-form-item>
									</div>
								</div>
							</div>
						</el-form-item>
						<el-table v-if="ruleForm2.awardType==1" :data="ruleForm2.awardGoods" style="width: 1100px;margin-left: 120px;margin-bottom: 20px;" >
							<el-table-column prop="goodsName" label="商品名称"></el-table-column>
							<el-table-column label="规格名">
								<template slot-scope="scope">
									<div v-if="scope.row.skuList.length">{{scope.row.skuList[0].specValues}}</div>
								</template>
							</el-table-column>
							<el-table-column label="商品图片">
								<template slot-scope="scope">
									<div>
										<img style="width: 60px;height: 60px;" :src="scope.row.mainImgUrl||scope.row.thumbImgUrl" alt="">
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="originalPrice" label="划线价"></el-table-column>
							<el-table-column prop="salePrice" label="销售价格"></el-table-column>
							<el-table-column prop="totalStock" label="商品库存"></el-table-column>
							<el-table-column label="操作" width="100">
								<template slot-scope="scope">
									<span style="color:#F56C6C;cursor:pointer;" @click="deleteAwardChose(scope.row)">删除</span>
								</template>
							</el-table-column>
						</el-table>

                        <uploadImg label='订单页图片' label-width="200px" prop='awardLogo' v-model="ruleForm2.awardLogo" 
                            required tips="建议尺寸80x106像素，支持JPG、PNG、GIF格式"
                            :rules="[{required:true,message:`奖品图片不能为空`,trigger: 'blur'}]"/>

						<el-form-item v-if="ruleForm2.awardType!=0" label="数量" prop="awardNum">
							<el-input onkeyup="this.value=this.value.replace(/\D/g,'')" size="mini" v-model.number="ruleForm2.awardNum" style="width: 120px;margin-left: 4px;"></el-input>
							<span style="margin-left: 6px;">份</span>
						</el-form-item>
						<el-form-item label="概率" prop="awardRate">
							<el-input onkeyup="this.value=this.value.replace(/\D/g,'')" size="mini" v-model.number="ruleForm2.awardRate" style="width: 120px;margin-left: 4px;"></el-input>
							<span style="margin-left: 6px;">%</span>
						</el-form-item>
						<div style="margin-left: 50px;font-size: 14px;color: #999;margin-top: 10px;">
							<div>中奖概率为用户每次抽奖时独立事件的概率。</div>
							<div>举例：若中奖率为10%，则每个用户中奖的概率为10%，并不是10个人中一定出现1个奖品。</div>
						</div>
						<div style="display: flex;align-items: center;justify-content: flex-end;margin-top: 20px;padding-right: 40px;">
							<el-button style="width: 100px;" @click="addDrawDialogShow=false">取消</el-button>
							<el-button style="width: 100px;margin-left: 20px;" type="primary" @click="addAwardSure()">确定</el-button>
						</div>
					</div>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog title="选择规格" :visible.sync="descDialogShow" width="1400px" class="dialog">
			<el-table height="350" :data="skuList" style="width: 100%;" :row-key="getRowKeys" @current-change="currentSelect" @selection-change="handleSelectionChange"
			ref="tab">
				<el-table-column type="selection" width="55" :selectable="checkSelect"></el-table-column>
				<el-table-column prop="specValues" label="规格名称"></el-table-column>
				<el-table-column prop="salePrice" label="销售价格"></el-table-column>
				<el-table-column prop="stock" label="现有库存"></el-table-column>
				<el-table-column label="状态" width="120">
					<template slot-scope="scope">
						<div v-if="scope.row.stock==0" class="table-button" style="color: #f00;">库存不足</div>
						<div v-else-if="scope.row.hasSecKill" class="table-button" style="color: #f00;">已选择</div>
					</template>
				</el-table-column>
			</el-table>
			<div style="display: flex;justify-content: space-between;margin-right: 20px;margin-left: 13px;margin-top: 20px;">
				<div>
					<el-button style="margin-right: 20px;width: 80px;" type="infor" @click="cancelEdit">取消</el-button>
					<el-button style="width: 80px;" type="primary" @click="joinPoint()">确定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		drawupdateActivity,
		addDrawActivity,
		drawloadActivity,
		getGoodsSampleDetail,
		findForLuckDraw,
	} from '@/api/goods.js';
	import {
		quillEditor
	} from 'vue-quill-editor'
	import selectProduce from './selectMultiPro.vue';
	import selectDrawProduce from './selectDrawMultiPro.vue';
	import config from '../../../config/index.js';
	import quillConfig from '@/components/quill-config.js'
    import selectLink from '@/views/components/selectLink';
    import uploadImg from '../../../components/Upload/uploadImg.vue'
     
	export default {
		components: {
			selectProduce,
			selectDrawProduce,
			quillEditor,
            uploadImg,
            selectLink
		},
		data() {
			return {
				loading: false,
				ruleForm: {
					activeName: '',
					activityTime: '',
					radioRole: '1',//用户身份选择
					roleValue: [],//选择部分用户时选择的用户类型
					drawWay:'1',//抽奖方式,0 不做限制, 1，下单 
					orderMoney:'',//抽奖订单满足金额
					orderMoneyNormal:'',//未选中时抽奖订单满足金额
					drawGoods:[],//抽奖选择商品
					drawOptionOrder:false,//抽奖条件订单金额
					drawOptionGoods:false,//抽奖条件商品
					drawOptioninv:false,//抽奖条件邀请人
					drawNum:'2',//参与次数选择
					drawNumber1:'',//每天抽奖次数
					drawNumber2:'',//总计抽奖次数
					drawNumberNormal1:'', //每天抽奖次数
					drawNumberNormal2:'', //总计抽奖次数
					invitationCount:'',//邀请数量
					inviterCount:'',//邀请人获取抽奖次数
					beInviterCount:'',//被邀请人获取抽奖次数
                    beInviterNeedPay:false, //被邀请人是否需要支付
				},
				ruleForm2: {
					awardGoods:[],//选择的奖励商品,
					awardType:'0',//奖品类型//0-未中奖 1-商品 2-优惠券 3-积分
					awardRate:'',//中间概率
					awardNum:'',//奖品数量
					awardLogo:'',//奖品图片
					awardName:'',
					awardCoupon:{},//奖品选择的优惠券
                    awardCouponId:'',   //优惠券ID
					awardPoint:'',//奖品选择的金豆数值
					choseAward:null,//选择的要添加为奖品的商品
					id:null,//奖品id
				},
				ruleForm3:{
					rules:'',
					showWinners:'0',//是否展示名单：0-不展示 1-展示
                    linkUrl:{},
                    shareTitle:'',  //分享标题
                    shareImgUrl:'',  //分享图片

                    style:1,                //样式选择 1老虎机 2盲盒
                    publicityImg:'',        //宣传图片
                    luckDrawImg:'',         //抽奖图片
                    prizeImg:'',            //奖品图片
                    winnerImg:'',           //中奖名单图片
                    payImg:'',              //支付结果图片
                    orderImg:'',            //我的订单页图片
                    notWinPrompt:'',        //未中奖提示语
                    notWinPromptType:0,     //未中奖提示语类型 0邀请好友 1再去逛逛
                    notWinLinkUrl:{},       //未中奖链接
                    noChancePrompt:'',      //无抽奖机会提示语
                    noChancePromptType:0,   //无抽奖机会按钮文字
                    noChanceLinkUrl:{},     //无抽奖机会链接
				},
				awardGoodsList:[],//选择的奖励列表,
				rules: {
					activeName: [{
							required: true,
							trigger: 'blur',
							message: '请输入活动名称',
						},
						{
							min: 1,
							max: 50,
							message: '最多输入50个字',
							trigger: 'blur'
						}
					],
					orderMoney: [{
						required: true,
						trigger: 'blur',
						message: '请填写订单金额',
					}],
					activityTime: [{
						required: true,
						message: '请选择活动时间',
						trigger: 'change'
					}],
					roleValue: [{
						type: 'array',
						required: true,
						message: '请选择用户身份',
						trigger: 'change'
					}],
					drawGoods: [{
						type: 'array',
						required: true,
						message: '请选择参与商品',
						trigger: 'change'
					}],
					drawNumber1: [{
						required: true,
						trigger: 'blur',
						message: '请填写每天抽奖次数',
					}],
					drawNumber2: [{
						required: true,
						trigger: 'blur',
						message: '请填写总计抽奖次数',
					}],
                    inviterCount: [{
						required: true,
						trigger: 'blur',
						message: '请填写邀请人次数',
					},
                    { type:"number" ,min: 1,message: '邀请人次数不能小于1', trigger: 'blur' }
                    ],
                    beInviterCount: [{
						required: true,
						trigger: 'blur',
						message: '请填写被邀请人次数',
					}],
					awardGoods: [{
						type: 'array',
						required: true,
						message: '请选择奖品',
						trigger: 'change'
					}],
					awardNum: [{
						required: true,
						trigger: 'blur',
						message: '请填写奖品数量',
					},
					{ type:"number" ,min: 1,message: '奖品数量不能小于1', trigger: 'blur' }],
					awardRate: [{
						required: true,
						trigger: 'blur',
						message: '请填写中奖概率',
					}],
					awardName: [{
							required: true,
							trigger: 'blur',
							message: '请输入奖品名称',
						},
						{
							min: 1,
							max: 50,
							message: '最多输入50个字',
							trigger: 'blur'
						}
					],
					choseAward: [{
						required: true,
						trigger: 'blur',
						message: '请选择商品',
					}],
				},
				page: {
					total: 0,
					size: 5,
					current: 1
				},
				addProductDialogShow: false,//抽奖条件选择的商品弹窗
				addDrawProduceDialogShow:false,//选择奖品的弹窗
				addDrawDialogShow:false,//添加奖品弹窗
				descDialogShow:false,//选择规格
				currentPage1: 1,
				pageSize1: 5,
				Total1: 0,
				activityId: 0, //活动id
				onlyLook: 0,
				imgApi: config.UPLOAD_IMG,
				imgUrl: config.IMG_BASE,
				pickerOptions: { //时间区间提示
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				goodsList: [],
				level: ["会员卡", "铂金卡", "钻石卡", "黑金卡"],
				
				roleOptions: [{
						value: '0',
						label: '注册用户'
					},
					{
						value: '3',
						label: '会员卡'
					},
					{
						value: '4',
						label: '铂金卡'
					},
					{
						value: '5',
						label: '钻石卡'
					},
					{
						value: '6',
						label: '黑金卡'
					}
				],
				activeTab:0,
				goodsPageList:[],//分页商品数据
				skuList:[],
				selectSkuList:[],
				currentIndex:-1,//当前编辑的下标
				quillOption: quillConfig, //富文本
				couponList:[],
                shopVisible:false,  //链接弹窗开关
			};
		},
		computed: {},
		beforeMount() {
			this.onlyLook = this.$route.query.isContinue==1;
			this.findByCouponName();
            if (this.$route.query.id) {
                console.log(this.$route.query.id);
                this.activityId = this.$route.query.id;
                this.getData();
            }
		},
		methods: {
            //获取优惠券数据
			async findByCouponName(){
				try{
					let res = await findForLuckDraw();
					if(res.success){
						this.couponList = res.data;
					}
				}catch(e){
					//TODO handle the exception
				}
			},
			async getData() {
				this.loading = true
				const res = await drawloadActivity({
					id: this.activityId
				})

				if (res.success) {
					this.ruleForm.activeName = res.data.activityName;
					this.ruleForm.drawWay = res.data.activityType +'';
					this.ruleForm.activityTime = [res.data.beginDate,res.data.endDate];
					this.ruleForm.radioRole = (res.data.userIdentity+1)+'';
                    this.ruleForm.roleValue = res.data.driverLevel;
					if(res.data.orderAmount>0){
						this.ruleForm.orderMoney = res.data.orderAmount+'';
						this.ruleForm.drawOptionOrder = true;
					}
					if(res.data.activityGoodsVOList){
						this.ruleForm.drawGoods = res.data.activityGoodsVOList;
						this.goodsPageList = this.ruleForm.drawGoods.slice(0,this.page.size);
						this.ruleForm.drawOptionGoods = true;
						this.page.total=this.ruleForm.drawGoods.length;
					}else{
						this.ruleForm.drawGoods = [];
						this.goodsPageList = [];
						this.ruleForm.drawOptionGoods = false;
					}
					this.ruleForm.drawNum = (res.data.luckDrawType+1)+'';
					if(res.data.luckDrawType==1){
						this.ruleForm.drawNumber1 = res.data.luckDrawCount;
					}else if(res.data.luckDrawType==2){
						this.ruleForm.drawNumber2 = res.data.luckDrawCount;
					}
                    this.ruleForm.invitationCount = res.data.invitationCount//邀请数量
                    if (res.data.invitationCount) {
                        this.ruleForm.drawOptioninv = true;
                    }
					this.ruleForm.inviterCount = res.data.inviterCount//邀请人获取抽奖次数
					this.ruleForm.beInviterCount = res.data.beInviterCount//被邀请人获取抽奖次数
					this.ruleForm.beInviterNeedPay = res.data.beInviterNeedPay ? true : false//被邀请人是否需要支付
					
					if(res.data.luckDrawPrizeResponseList){
						var list2 = [];
						res.data.luckDrawPrizeResponseList.map(item=>{
							var chose = null;
							if(item.activityGoodsVOList){
								chose = item.activityGoodsVOList[0];
							}
							var obj = {
								awardGoods:chose?[chose]:[],//选择的奖励商品,
								awardType:item.prizeType+'',//奖品类型
								prizeTypeStr:item.prizeTypeStr,//奖品类型
								awardRate:item.probability,//中间概率
								awardNum:item.goodsCount,//奖品数量
								awardLogo:item.imgUrl,//奖品图片
								awardName:item.prizeName,
								issuedGoodsCount:item.issuedGoodsCount||0,
								awardCoupon:{},//奖品选择的优惠券
                                awardCouponId:item.couponId,
								awardPoint:item.integral>0?item.integral:'',//奖品选择的金豆数值
								choseAward:chose,//选择的要添加为奖品的商品
								id:item.id,
							};

                            this.couponList.map(its=>{
                                obj.awardCoupon.id = item.couponId;
								if(its.id==item.couponId){
                                    obj.awardCoupon.num = its.num
								}
							})
							list2.push(obj);
							return item;
						})
						this.awardGoodsList = list2;
					}
                    this.ruleForm3 = {
                        rules:res.data.rules,
                        showWinners:res.data.showWinners+'',//是否展示名单：0-不展示 1-展示
                        linkUrl:{},
                        shareTitle: res.data.shareTitle,  //分享标题
                        shareImgUrl: res.data.shareImgUrl,  //分享图片

                        style:res.data.style,                //样式选择 1老虎机 2盲盒
                        publicityImg:res.data.publicityImg,        //宣传图片
                        luckDrawImg:res.data.luckDrawImg,         //抽奖图片
                        prizeImg:res.data.prizeImg,            //奖品图片
                        winnerImg:res.data.winnerImg,           //中奖名单图片
                        payImg:res.data.payImg,              //支付结果图片
                        orderImg:res.data.orderImg,            //我的订单页图片
                        notWinPrompt:res.data.notWinPrompt,        //未中奖提示语
                        notWinPromptType:res.data.notWinPromptType,     //未中奖提示语类型 0邀请好友 1再去逛逛
                        notWinLinkUrl:{},       //未中奖链接
                        noChancePrompt:res.data.noChancePrompt,      //无抽奖机会提示语
                        noChancePromptType:res.data.noChancePromptType,   //无抽奖机会按钮文字
                        noChanceLinkUrl:{},     //无抽奖机会链接
                    };
					
                    try {
                        this.ruleForm3.linkUrl = JSON.parse(res.data.linkUrl);
                        this.ruleForm3.notWinLinkUrl = res.data.notWinLinkUrl ? JSON.parse(res.data.notWinLinkUrl) : {};
                        this.ruleForm3.noChanceLinkUrl = res.data.noChanceLinkUrl ? JSON.parse(res.data.noChanceLinkUrl) : {};
                    } catch (error) {
                        console.log(error);
                    }
				} else {
					this.$message({
						type: 'error',
						showClose: true,
						message: res.alertMsg
					})
				}
				this.loading = false

			},

			choseActive(type) {
				if(type==1){
					this.addProductDialogShow = true;
				}else{
					this.addDrawProduceDialogShow = true;
				}
			},

			getSelectList(data) {
				console.log("---SelectList1----", data)
				this.page.total = data.length;
				this.addProductDialogShow = false
				this.ruleForm.drawGoods = data;
				this.goodsPageList = this.ruleForm.drawGoods.slice(0,this.page.size)
			},
			getDrawSelectList(data){
				this.addDrawProduceDialogShow = false
				// this.ruleForm2.awardGoods = data;
				this.ruleForm2.choseAward = data[0];
				console.log("---SelectList2----", data)
				this.getSkuList();
			},
			deleteChose(val) {
				let _this = this;
				this.$alert('确定删除当前商品吗', '温馨提示', {
					confirmButtonText: '确定',
					showCancelButton: true,
					callback: action => {
						if (action == 'confirm') {
							var list = [];
							_this.ruleForm.drawGoods.map(item=>{
								if(val.id!=item.id){
									console.log("---item---",item)
									list.push(item);
								}
								return item;
							})
							_this.ruleForm.drawGoods = list;
							_this.page.total = list.length;
							
							this.handleCurrentChange(this.page.current);
							// _this.$message({
							// 	showClose: true,
							// 	type: 'success',
							// 	message: '删除成功'
							// })
						}
					}
				});
			},

			handleCancel() {
				this.$router.back();
			},

			saveBtn() {
                this.$refs['ruleForm3'].validate((valid) => {
                    if (valid) {
                        this.drawWayChange();
                        this.sureSave();
                    } else {
                        return false;
                    }
                });
			},
			async sureSave() {
                console.log("---params---", 11);
				this.loading = true
				try {
					var list = [];
					this.awardGoodsList.map(item=>{
						var goodsList = [];
						if(item.awardType==1){
							item.awardGoods.map(its=>{
								var obj={};
								obj.goodsId = its.id;
								var descList = [];
								its.skuList.map(items=>{
									var skuObj = {
										skuId:items.skuId
									}
									descList.push(skuObj);
									return items;
								})
								obj.luckDrawSkuGoodsVOList=descList;
								goodsList.push(obj);
								return its;
							})
						}
						
						var obj = {
							goodsCount:item.awardNum,
							imgUrl:item.awardLogo||'',
							integral:item.awardPoint,
							prizeName:item.awardName,
							prizeType:item.awardType,//0-未中奖 1-商品 2-优惠券 3-积分
							probability:item.awardRate,
							luckDrawGoodsVOList:goodsList,
							id:item.id,
						};

                        if (this.activityId == 0) {
                            obj.couponId = item.awardType==2?item.awardCoupon.id:'';
                        }else{
                            obj.couponId = item.awardType==2?item.awardCoupon.id:'';
                        }
                        
						list.push(obj)
						return item;
					})
					var goodlist = [];
					if(this.ruleForm.drawOptionGoods){
						this.ruleForm.drawGoods.map(item=>{
							var obj = {
								goodsId:item.id
							}
							goodlist.push(obj);
							return item;
						})
					}
					const params = {
						activityName: this.ruleForm.activeName,
						beginDate: this.ruleForm.activityTime[0],
						endDate: this.ruleForm.activityTime[1],
						activityType: this.ruleForm.drawWay,//活动类型：0-下单抽奖 1-积分抽奖
						driverLevel: this.ruleForm.roleValue, //推手级别：0-不开启 1、2 、3金卡； 4 铂金卡；5 钻石卡；6 黑金卡；
						luckDrawGoodsVOList:goodlist,//商品id列表
						luckDrawPrizeRequest:list,//奖品信息
						luckDrawType:parseInt(this.ruleForm.drawNum)-1,//参与抽奖的类型 0-不做限制 1-每个账号每天可抽n次 2-每个账号最多可抽n次
						orderAmount:this.ruleForm.drawOptionOrder? this.ruleForm.orderMoney:0,//订单金额
                        ...this.ruleForm3,
						userIdentity:parseInt(this.ruleForm.radioRole)-1,//参与用户身份：0-所有用户 1-部分用户
						userLayer:0,//用户分层：0-不开启 其他信息待补充
                        invitationCount:this.ruleForm.invitationCount,//邀请数量
                        inviterCount:this.ruleForm.inviterCount,//邀请人获取抽奖次数
                        beInviterCount:this.ruleForm.beInviterCount,//被邀请人获取抽奖次数
                        beInviterNeedPay:this.ruleForm.beInviterNeedPay ? 1 : 0,//被邀请人是否需要支付
					}
                    params.linkUrl = JSON.stringify(this.ruleForm3.linkUrl);//跳转链接
                    params.notWinLinkUrl = JSON.stringify(this.ruleForm3.notWinLinkUrl);//跳转链接
                    params.noChanceLinkUrl = JSON.stringify(this.ruleForm3.noChanceLinkUrl);//跳转链接
                    
					if(this.ruleForm.drawNum!=1){
						params.luckDrawCount=parseInt(this.ruleForm.drawNum)==2?this.ruleForm.drawNumber1:this.ruleForm.drawNumber2;//参与次数
					}else{
						params.luckDrawCount=0;//参与次数
					}

                    if (this.ruleForm.drawWay == 3 && !this.ruleForm.drawOptioninv) {
                        params.invitationCount = '';
                        params.inviterCount = '';
                        params.beInviterCount = '';
                        params.beInviterNeedPay = false;
                        
                    }
					console.log("---params---", params);
					let res = null;

					if (this.activityId > 0) {
						params.id = this.activityId;
						res = await drawupdateActivity(params);
					} else {
						res = await addDrawActivity(params);
					}
					if (res.success) {
						this.$message({
							type: 'success',
							showClose: true,
							message: this.activityId > 0 ? '编辑成功' : '添加成功'
						})
						this.$router.push({
							path: '/market/luckDraw/index'
						})
					} else {
						this.$message({
							type: 'error',
							showClose: true,
							message: res.alertMsg
						})
					}
				} catch (e) {
					this.loading = false
                    console.log(e);
				} finally {
					this.loading = false
				}
			},
			handleNext(){
				console.log("------",this.ruleForm);
				if(this.activeTab==0){
					this.$refs['ruleForm'].validate((valid)=>{
						if(valid){
							this.activeTab=1;
						}else{
							console.log('error submit!!');
							return false;
						}
					})
				}else if(this.activeTab==1){
					if(!this.awardGoodsList.length){
						this.$message({
							type: 'error',
							showClose: true,
							message: '请选择奖品'
						})
						return ;
					}
					if(this.awardGoodsList.length<4){
						this.$message({
							type: 'error',
							showClose: true,
							message: '最少选择4个奖品'
						})
						return ;
					}
					if(this.awardGoodsList.length>12){
						this.$message({
							type: 'error',
							showClose: true,
							message: '最多选择12个奖品'
						})
						return ;
					}
					this.activeTab=2;
				}
			},
			handleBack(){
				if(this.activeTab==2){
					this.activeTab=1;
				}else if(this.activeTab==1){
					this.activeTab=0;
				}
			},
			// 切换显示条数
			handleSizeChange (val) {
				this.page.size = val;
				this.goodsPageList = this.ruleForm.drawGoods.slice(0,this.page.size);
			},
			// 翻页
			handleCurrentChange (val) {
				this.page.current = val;
				var totalPage =parseInt(this.ruleForm.drawGoods.length/this.page.size);
				if(this.ruleForm.drawGoods.length%this.page.size!=0){
					totalPage = totalPage+1;
				}
				var num = this.page.size*(this.page.current-1);
				if(this.page.current==totalPage){
					this.goodsPageList = this.ruleForm.drawGoods.slice(num,this.ruleForm.drawGoods.length);
				}else{
					this.goodsPageList = this.ruleForm.drawGoods.slice(num,num+this.page.size);
				}
				if(this.goodsPageList.length==0){
					this.ruleForm.drawOptionGoods = false;
				}
				console.log("-----",this.goodsPageList);
			},
			//增加奖品确定
			addAwardSure(){
				console.log("---addAwardSure---",this.ruleForm2);
				this.$refs['ruleForm2'].validate((valid)=>{
					if(valid){
						if(this.ruleForm2.awardType==2){
							if(!this.ruleForm2.awardCoupon){
								this.$message({
									type: 'error',
									showClose: true,
									message: '请选择奖品优惠券'
								})
								return;
							}
							if(this.ruleForm2.awardNum>this.ruleForm2.awardCoupon.num){
								this.$message({
									type: 'error',
									showClose: true,
									message: '奖品数量超出所选优惠券可用数量'
								})
								return;
							}
						}else if(this.ruleForm2.awardType==3){
							if(!this.ruleForm2.awardPoint){
								this.$message({
									type: 'error',
									showClose: true,
									message: '请填写奖品金豆数量'
								})
								return;
							}
						}else if(this.ruleForm2.awardType==1){
							if(this.ruleForm2.awardNum>this.ruleForm2.awardGoods[0].totalStock){
								this.$message({
									type: 'error',
									showClose: true,
									message: '奖品数量超出所选商品库存数量'
								})
								return;
							}
						}
						var rateTotal = 0;
						this.awardGoodsList.map(item=>{
							if(this.currentIndex==-1){
								rateTotal+=parseInt(item.awardRate);
							}
							return item;
						})
						rateTotal = rateTotal+parseInt(this.ruleForm2.awardRate);
						if(rateTotal>100){
							this.$message({
								type: 'error',
								showClose: true,
								message: '奖品中奖概率之和不可超过100%'
							})
							return;
						}
						var obj = {
							awardGoods:[this.ruleForm2.choseAward],//选择的奖励商品,
							awardType:this.ruleForm2.awardType,//奖品类型
							awardRate:this.ruleForm2.awardRate,//中间概率
							awardNum:this.ruleForm2.awardNum,//奖品数量
							awardLogo:this.ruleForm2.awardLogo,//奖品图片
							awardName:this.ruleForm2.awardName,
							awardCoupon:this.ruleForm2.awardCoupon,//奖品选择的优惠券
							awardPoint:this.ruleForm2.awardPoint,//奖品选择的金豆数值
							choseAward:this.ruleForm2.choseAward,//选择的要添加为奖品的商品
						};
						if(this.currentIndex!=-1){//编辑
							obj.id = this.ruleForm2.id;//奖品id
							obj.issuedGoodsCount = this.ruleForm2.issuedGoodsCount;
							var list = this.awardGoodsList;
							list[this.currentIndex] = obj;
							// this.awardGoodsList.map((item,index)=>{
							// 	if(index == this.currentIndex){
							// 		item = obj;
							// 	}
							// 	return item;
							// })
							this.awardGoodsList = JSON.parse(JSON.stringify(list));
						}else{
							obj.id = null;
							obj.issuedGoodsCount=0;//已发放数量
							this.awardGoodsList.push(obj);
						}
						// this.$refs['ruleForm2'].resetFields();
						// this.ruleForm2.awardGoods = [];
						this.addDrawDialogShow = false;
					}else{
						console.log('error submit!!');
						return false;
					}
				})
			},
			addAwardDialogShow(){
				this.currentIndex = -1;
				this.ruleForm2 = {
					awardGoods:[],//选择的奖励商品,
					awardType:'0',//奖品类型//0-未中奖 1-商品 2-优惠券 3-积分
					awardRate:'',//中间概率
					awardNum:'',//奖品数量
					awardLogo:'',//奖品图片
					awardName:'',
					awardCoupon:{},//奖品选择的优惠券
					awardPoint:'',//奖品选择的金豆数值
					choseAward:null,//选择的要添加为奖品的商品
				},
				this.addDrawDialogShow=true;
			},
			//选择规格
			async getSkuList() {
				let data = {
					id: this.ruleForm2.choseAward.id,
				};
				let res = await getGoodsSampleDetail(data);
				if (res.success) {
					this.skuList = res.data.skuSampleVOList;
					this.descDialogShow = true;
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg
					});
				}
			},
			getRowKeys(row) {
				return row.skuId + '';
			},
			currentSelect(val){
				this.$refs.tab.toggleRowSelection(val)
				console.log("-----",val);
			},
			handleSelectionChange(val) {
				this.selectSkuList = val;
				if (val.length > 1) {
					this.$refs.tab.clearSelection();
					this.$refs.tab.toggleRowSelection(val.pop());
				} 
			},
			tabCheckedChange() {
				this.$refs['tab'].toggleAllSelection();
			},
			cancelEdit(){
				this.descDialogShow = false;
				this.skuList = [];
				this.selectSkuList = [];
				this.ruleForm2.awardGoods=[];
				this.ruleForm2.choseAward = null;
			},
			joinPoint(){
                if (!this.selectSkuList.length) {
                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: '请选择规格'
                    })
                    return;
                }
                this.descDialogShow = false;
				if(this.ruleForm2.choseAward){
					this.ruleForm2.choseAward.skuList=this.selectSkuList;
					this.ruleForm2.awardGoods = [this.ruleForm2.choseAward];
				}
			},
			//奖品编辑
			eidtAwardInfo(index){
				this.ruleForm2 = JSON.parse(JSON.stringify(this.awardGoodsList[index]));
				this.currentIndex = index;
				console.log("---ruleForm2---",this.ruleForm2)
				console.log("---currentIndex---",this.currentIndex)
				this.addDrawDialogShow = true;
			},
			deleteAwardChose(){
				this.ruleForm2.awardGoods=[];
				this.ruleForm2.choseAward=null;
			},
			delAwardInfo(index){
				var list = [];
				this.awardGoodsList.map((item,indexs)=>{
					if(index!=indexs){
						list.push(item);
					}
					return item;
				})
				this.awardGoodsList=list;
			},
			drawNumChange(){
				this.ruleForm.drawNumberNormal1='';
				this.ruleForm.drawNumberNormal2='';
			},
            //抽奖条件修改
            drawWayChange(){
                if (this.ruleForm.drawWay == 0) {
                    console.log(0);
                }else if (this.ruleForm.drawWay == 1) {
                    console.log(1);
                }else if (this.ruleForm.drawWay == 2) {
                    this.ruleForm.drawNumber1='';//每天抽奖次数
                    this.ruleForm.drawNumberNormal1=''; //每天抽奖次数
                }
                // this.ruleForm.drawNumber1='';//每天抽奖次数
                // this.ruleForm.drawNumber2='';//总计抽奖次数

                // this.ruleForm.orderMoney='';//抽奖订单满足金额
                // this.ruleForm.orderMoneyNormal='';//未选中时抽奖订单满足金额
                // this.ruleForm.drawGoods=[];//商品数据
                // this.ruleForm.drawNumberNormal1=''; //每天抽奖次数
                // this.ruleForm.drawNumberNormal2=''; //总计抽奖次数

                // this.ruleForm.invitationCount='';//邀请数量
                // this.ruleForm.inviterCount='';//邀请人获取抽奖次数
                // this.ruleForm.beInviterCount='';//被邀请人获取抽奖次数
                
            },
			goodsCheck(){
				if(!this.ruleForm.drawOptionGoods){
					this.goodsPageList = [];
				}else{
					if(this.ruleForm.drawGoods.length){
						this.page.total=this.ruleForm.drawGoods.length;
						console.log("-----",this.page.total)
						this.handleCurrentChange(1);
					}
				}
			},
            changeawardCoupon(e){
                console.log(e);
                let _this = this;
                this.couponList.map(item =>{
                    if(item.id == e){
                        _this.ruleForm2.awardCoupon = item;
                    }
                })
            },
            //判断当前规格是否允许选中
            checkSelect(row){
                console.log(row);
                return !!row.stock;
            }
		},

	}
</script>

<style lang="less" scoped>
	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	.discount-container {
		.discount-item {
			display: inline-block;
		}
	}

	.discount-item {
		/deep/.el-input__inner {
			color: #909399 !important;
		}
	}

	.activepromotionMethod {
		/deep/.el-input__inner {
			color: #333 !important;
		}
	}

	.el-form-date-pick {
		display: inline-block;
	}
	.box{
		position: absolute;
		top: 0;
		right: 0;
		width:0;
		height:0;
		border-top: 30px solid transparent;
		border-left: 20px solid #006fa5;
		border-bottom: 30px solid transparent;
	}
	.top-tab{
		flex: 1;
		text-align: center;
		background-color: #006fa5;
		height: 60px;
		line-height: 60px;
		color: #fff;
		font-size: 18px;
		font-weight: bold;position: relative;
	}
	.bottom-save-btn {
		position: fixed;
		width: 100%;
		background: #fff;
		bottom: 0;
		text-align: center;
		padding: 20px 0;
		z-index: 100;
		box-shadow: 13px 1px 6px #999;
	}
	/deep/.el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		height: 146px;
	}
	
	/deep/.el-upload:hover {
		border-color: #409eff;
	}
	.baseInformtion {
		width: 100%;
		background: #fff;
		padding: 10px;
		margin-bottom: 12px;
	
		.infortion {
			margin-bottom: 20px;
			padding: 10px 0;
			border-bottom: 1px solid #ccc;
		}
	}
</style>
