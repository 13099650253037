<template>
	<div class="contanier">
		<div class="filter-container title-border">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">商品名称:</label>
				<el-input v-model="searchKey" style="width: 200px;" placeholder="商品名称"></el-input>
			</div>
			<!-- 商品分组 -->
			<div class="filter-item">
				<label class="label">商品分组:</label>
				<el-select v-model="group" style="width: 200px;" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option
						v-for="(item,index) in groupOptions"
						:key="index"
						:label="item.groupName"
						:value="item.groupId"
					></el-option>
				</el-select>
			</div>
			<!-- 商品状态 -->
			<div class="filter-item">
				<label class="label">商品标签: </label>
				<el-select v-model="tagId" style="width: 200px;" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in tagsList" :key="item.id" :label="item.tagsName" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin:0 10px;width:90px;" @click="handleFilter">查询</el-button>
			</div>
		</div>
		<!-- table -->
		<div class="table-container content">
			<el-table
				height="450"
				:data="tableDataList"
				v-loading="loading"
				style="width: 100%"
				ref="compSelectProTable"
				:row-key="getRowKeys"
				@selection-change="handleSelectionChange"
			>
				<el-table-column :selectable="checkSelect" type="selection" width="55" :reserve-selection="true"></el-table-column>
				<el-table-column prop="goodsName" label="商品" width="600">
					<template slot-scope="scope">
						<div class="dialog-name-content">
							<img v-if="scope.row.thumbImgUrl" :src="imgUrl+scope.row.thumbImgUrl" />
							<div class="right" style="align-items: center;">
								<div class="name" style="width: 500px;">
									{{scope.row.goodsName}}
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="originalPrice" label="划线价"></el-table-column>
				<el-table-column prop="salePrice" label="销售价"></el-table-column>
				<el-table-column prop="totalStock" label="库存">
					<template slot-scope="scope">
						<div class="dialog-name-content">
							{{scope.row.totalStock}}
							<span style="margin-left: 4px;color: #FF4D51;" v-if="scope.row.totalStock<=0">（库存不足）</span>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<el-checkbox
				style="margin-top:25px;margin-left:13px;float:left;"
				:value="checkedAll"
				@change="selectCurrentAll"
			>当前页全选</el-checkbox>
			<el-pagination
				v-if="page.total"
				style="margin-top:20px;float:right;"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="page.current"
				:page-sizes="[5,10, 15, 20, 25, 30]"
				:page-size="page.size"
				layout="total, sizes, prev, pager, next, jumper"
				:total="page.total"
			></el-pagination>
		</div>
		<div slot="footer" class="dialog-footer">
			已选中
			<span style="color:#F56C6C">{{multipleSelection.length}}</span>
			件
			<el-button class="button" @click="saveSelectProList" style="margin-left:10px;" type="primary">确定</el-button>
		</div>
	</div>
</template>
<script>
import {
	productGroupListData,
	activityGoodsList,
	listTags,
} from "@/api/goods.js";

import config from '@/config/index'

export default {
	name: 'selectProduce',
	props: {
		api: String, //弹框商品列表请求接口名称, 统一加在 api/popGoods.js里
		selectedData: { //需要选中的商品列表
			type: Array,
			default: () => {
				return []
			}
		},
		couponData: {
			type: Object,
			default: () => {
				return {}
			}
		},
		params: { //自定义请求参数
			type: Array,
			default: () => {
				return []
			}
		},
		isShowCheck: {
			type: Boolean,
			default: () => {
				return true
			}
		},
		isShowSpecValue: {
			type: Boolean,
			default: () => {
				return false
			}
		},
		//是否是组合商品
		isCompose: {
			type: Boolean,
			default: () => {
				return false
			}
		}
	},
	data () {
		return {
			baseAPI: config.BASE_URL,
			imgUrl: config.IMG_BASE,
			searchKey: '',
			searchCode: '',
			type: null,
			typeOptions: [{
				value: 0,
				label: '单品销售'
			},
			{
				value: 1,
				label: '组合销售'
			}
			],
			group: null,
			groupOptions: [],
			goodsStatus: null,
			goodsStatusOptions: [
				{
					value: 0,
					label: '已下架'
				},
				{
					value: 1,
					label: '上架中'
				},
				{
					value: 2,
					label: '售罄'
				}
			],
			page: {
				total: 0,
				current: 1,
				size: 10
			},
			tableDataList: [],
			selectCount: 0,
			multipleSelection: [],
			checkedShopPro: true,
			tagsList:[],//商品标签数据
			loading: false,
			tagId:null,//商品标签id
		}
	},
	created () {
		this.getTagList();
		this.getGroupList();
		this.getSingleList();

	},
	watch: {},
	computed: {
		checkedAll () {
			var val = this.multipleSelection;
			let ableSelectLength = 0 //获取当前页可选的全部页数
			var ids = val.map(item => {
				return item.id
			})
			this.tableDataList.forEach(item => {
				if (ids.indexOf(item.id) > -1) {
					ableSelectLength++;
				}
			});
			return (ableSelectLength > 0 && this.tableDataList.length == ableSelectLength) ? true : false;
		}
	},
	mounted () {


	},
	methods: {
		//获取商品标签数据
		async getTagList () {
			try {
				let data = {
					pageNo: 0,
					pageSize: 1000,
				};
				let res = await listTags(data);
				if (res.success) {
					this.tagsList = res.data.records;
				}
			} catch (e) {
				//TODO handle the exception
				console.log(e);
			}
		},
		// 获取分组列表数据
		async getGroupList () {
			try {
				// this.loading = true
				let data = {
					pageNo: 0, // 略过数据
					pageSize: 10000, // 取的数据
				}
				let result = await productGroupListData(data);
				this.groupOptions = result.data.records || [];
		
			} catch (e) {
				console.log(e)
			} 
		
		},
		async getSingleList () {
			this.loading = true;
			try {
				let data = {
					pageNo: this.page.current, // 略过数据
					pageSize: this.page.size, // 取的数据
					categoryId:this.group,
					goodsName:this.searchKey,
					goodsTags:this.tagId
				};
				let result = await activityGoodsList(data);
				this.page.total = result.data.total;
				this.tableDataList = result.data.records;

			} catch (error) {
				console.log(error);
			} finally {
				if (!this.isReady) {
					console.log("------", this.selectedData)
					this.selectedData.map(item => {
						this.$refs['compSelectProTable'].toggleRowSelection(item, true);
					})
					this.isReady = true;
				}
				this.loading = false;
			}
		},
		//外部全选当前页
		selectCurrentAll (e) {
			this.$refs['compSelectProTable'].toggleAllSelection();
			console.log(e);
		},
		//当前页全选
		handleSelectionChange (val) {
			this.multipleSelection = val;
		},
		// 切换显示条数
		handleSizeChange (val) {
			this.page.size = val;
			this.getSingleList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.page.current = val;
			this.getSingleList()
		},
		// selectInit(row, id) {
		// 	return (row.Remark && row.Remark != '') ? false:true
		// },
		clearSelection () {
			this.$nextTick(() => {
				this.$refs.compSelectProTable.clearSelection();
			});
		},
		getRowKeys (row) {
			return row.id;
		},
		handleFilter () {
			console.log(this.group);
			this.page.current = 1;
			this.getSingleList();
		},
		//关闭弹框，以及曝光选择的列表
		saveSelectProList () {
			if (!this.multipleSelection.length) {
				this.$message({
					showClose: true,
					message: '请选择商品',
					type: 'info'
				})
				return
			}
			this.$emit('getSelectList', this.multipleSelection)
		},
		/**
		 * row：当前行数据
		 * index：当前第几位
		 */
		checkSelect(row, index) {
			let isChecked = true;
			if (!row.noChoiceReason) { // 判断里面是否存在某个参数
				isChecked = true
			} else {
				isChecked = false
			}
			return isChecked
		},
	}
}
</script>
<style lang="less" scoped>
.contanier {
	position: relative;
	overflow: hidden;
}

.content {
	overflow: hidden;
}

.dialog-footer {
	margin-top: 20px;
	text-align: center;

	.button {
		width: 150px;
	}
}

.dialog-name-content {
	margin: 10px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	img {
		width: 60px;
		height: 60px;
		object-fit: cover;
		margin-right: 10px;
	}
}
.noImgIcon {
	width: 60px;
	height: 60px;
}
</style>
