<template>
    <div>
        <div class="link-modal-info" @click="shopVisible = true;">
            <div class="link-info" v-if="!linkUrl.data">
                <div class="cont" style="background: transparent;color: #409eff;">请选择页面</div>
                <div class="icon" style="background: transparent;">
                    <i class="el-icon-arrow-down" style="color: #409eff;font-size: 16px;background: transparent;"></i>
                </div>
            </div>
            <div class="link-info" v-else>
                <div class="cont">{{linkUrl.text}}</div>
                <div class="icon flex center">
                    <i class="el-icon-arrow-down" style="color: #fff;font-size: 16px;"></i>
                    <div style="margin-left: 10px;font-size:14px" @click.stop="dataChange({})">
                        x
                    </div>
                </div>
                
            </div>
        </div>

        <!-- 选择链接弹窗 -->
        <link-modal 
            :showLiveTab="true" 
            :visible="shopVisible" 
            @change="dataChange" 
            @cancel="shopVisible=false">
        </link-modal>
    </div>
</template>
<script>

import linkModal from './linkModal.vue';
export default {
    components: {
        linkModal
    },
    props: {
        value: { 
			type:Object,
			default: () =>{
                return {};
            }
		},
		
    },
    computed: {
		linkUrl:{
			get() {
				return this.value;
			},
			set(newV) {
				this.$emit('input', newV);
			}
		}
	},
    data () {
        return {
            shopVisible:false,  //链接弹窗开关
        };
    },
    methods: {
        //链接变更
        dataChange(record){
            this.$emit('input',record);
        },
    }
};
</script>
