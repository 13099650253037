<template>
    <el-form-item v-bind="$props" v-on="$listeners">
        <div class="list-wrap" >
            <div
                v-show="imgUrl"
                class="image-item"
                :style="{ backgroundImage: `url(${imgUrl}?x-oss-process=image/resize,m_fixed,h_${height},w_${width})`,width: `${width}px`,height: `${height}px`}"
                @mouseover.prevent="homeBottomColourIsHover = true"
                @mouseleave.prevent="homeBottomColourIsHover = false"
            >
                <div class="mask" v-show="homeBottomColourIsHover">
                    <i class="el-icon-delete bin" @click="imgUrl = ''"></i>
                </div>
            </div>
            <el-upload
                v-show="!imgUrl"
                class="upload-machine"
                :action="imgApi"
                :show-file-list="false"
                :on-success="handleSuccess"
                :before-upload="beforerUpload"
				:style="`width:${width}px;height:${height}px;`"
            >
                <i
                    class="el-icon-plus"
                    :style="`width:${width}px;line-height: ${height}px;font-size: 24px;color:#999999;text-align: center;`"
                ></i>
            </el-upload>
            <div style="color: #666;" v-if="tips">{{tips}}</div>
        </div>
    </el-form-item>
</template>

<script>
import { FormItem } from 'element-ui';
console.log('FormItem.props',FormItem.props);
import config from '@/config/index'
export default {
	name: 'uploadImg',
	components: {
		
	},
	props: {
		...FormItem.props,
		value: { 
			type:String,
			default: '',
		},
		width:{
			type:[Number,String],
			default: '146',
		},
		height:{
			type:[Number,String],
			default: '146',
		},
        tips:{
            type: String,
            default:''
        }
	},
	computed: {
		imgUrl:{
			get() {
				return this.value;
			},
			set(newV) {
				this.$emit('input', newV);
			}
		}
	},
	data() {
		return {
			imgApi: config.UPLOAD_IMG,
			homeBottomColourIsHover:false,  //图片是否显示删除图标
		}
	},
	methods: {
		// 上传成功钩子
		handleSuccess (res) {
			this.$emit('input',res.data[0]);
			return true;
		},
		// 上传前钩子
		beforerUpload (file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
			const isLt3M = file.size / 1024 / 1024 < 3;
			if (!isJPG) {
				this.$message.error('图片必须是 JPG 或者PNG格式!');
			}
			if (!isLt3M) {
				this.$message.error('图片大小请控制在3M以内!');
			}
			return isJPG && isLt3M;
		},
	}
}
</script>

<style lang="less" scoped>
	.list-wrap{
		.image-item{
			width: 148px;
			height: 148px;
			position: relative;
			margin-right: 10px;
			margin-bottom: 10px;
			border: 1px solid #c0ccda;
			background-size: 100% 100%;
			border-radius: 6px;
			overflow: hidden;
			cursor: pointer;
		}
		.label{
			width: 46px;
			height: 26px;
			background-color: #13ce66;
			color: #FFFFFF;
			transform: rotate(45deg);
			text-align: center;
			position: absolute;
			right: -17px;
			top: -7px;
			.icon-success{
				transform: rotate(-45deg);
			}
		}
		.mask{
			width: 100%;
			height: 100%;
			border-radius: 6px;
			background-color: rgba(0, 0, 0, 0.5);
			position: relative;
			.bin{
				color: #FFFFFF;
				font-size: 20px;
				position: absolute;
				left: 45%;
				top: 43%;
			}
		}
		/deep/.el-upload{
			height: 100%;
		}
	}
</style>
